



import logo from './logo.svg';
import './App.css';
import Home from './Home';
import Home2 from './Home2';
import HomeCurrents from './HomeCurrents';
import Text from './Text';



function App() {
  
  return (
    <div className="App" style={{position: 'relative'}}>
      <Home />
{/*       
      <div className='infanim'>
      <div className='infinity '>
        <div className='infblur'>
        <img className="infimg inf" src='infinity_pink.png' />
        </div>
      </div>  
      <div className='quote'>
        <img className="infimg qu" src='quote_pink.png' />
      </div>  
      <div className='ripple'>
        <img className='rippleimg' src='ripple.png'/>
      </div>
      <div className='ripple'>
        <img className='rippleimg2' src='ripple.png'/>
      </div>
      <div className='ripple'>
        <img className='rippleimg3' src='ripple.png'/>
      </div>
      </div> */}
      
      {/* <Home2 />
      <HomeCurrents /> */}
   {/* <Text /> */}

    </div>
  );
}

export default App;
